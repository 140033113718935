import React from 'react'
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Loading from '../components/Loading';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };


const Omkroatia = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);

  const [error, setError] = useState(null);
  // Dette er hjemdata
  const [omkroatia, setOmkroatia] = useState();

  useEffect(() => {
  
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetch("https://cherry-surprise-68554.herokuapp.com/api/page-om-kroatia?populate=*", { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setOmkroatia(data))
      .catch((error) => setError(error))

    }, [])

    useEffect(() => {
      // Simulate loading progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 5;
        setPercentage(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setIsLoading(false); // Loading is complete, set isLoading to false after a delay
          }, 100); // Delay before setting isLoading to false (0.5 seconds)
        }
      }, 10); // Simulated loading interval in milliseconds
  
      // Fetch data or perform other loading tasks here
      // Example: fetchData();
  
      return () => {
        // Clear any cleanup tasks if needed
        clearInterval(interval);
      };
    }, []);


  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }



  return (
    <div>
      {isLoading ? (
        <Loading percentage={percentage} />
      ) : (
          <main className={`page landing-page sf-rel-top fade-in`}>
            {/* <section>
                    <div className="hero-prosjekter"></div>
                </section>
                */}
             
              <section className="clean-block clean-info dark pt-4 bg-white ">
                  <div className="container py-5">
                      <div className="row">
                          <div className="col-xl-12 offset-xl-0 text-left mx-auto">
                              <h1 className="text-info font-weight-bold">{omkroatia && omkroatia.attributes.OmKroatia_overskrift}</h1>
                            </div>

                            <div className="col-xl-12 offset-xl-0 text-left mx-auto pt-5">
                              <ReactMarkdown className="line-break" children={omkroatia && omkroatia.attributes.OmKroatia_tekst.replace(/\n/gi, "&nbsp; \n")} />
                          </div>
                      </div>
                  </div>
                  





                  

                    
                </section>
            </main>
            )}

            {isLoading && (
            <div className="loading-bar">
              <div className="loading-progress" style={{ width: `${percentage}%` }}></div>
            </div>
            )}
      
          </div>
  )
}

export default Omkroatia