//import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '../index.css';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Oval } from  'react-loader-spinner';
import ReactMarkdown from 'react-markdown';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Loading from '../components/Loading';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };




const Prosjektone = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [percentage, setPercentage] = useState(0);

    const [error, setError] = useState(null);
    // This is the carousel
    const [carouselforside, setCarouselforside] = useState(null);

    useEffect(() => {

        const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
        clearTimeout(timer);

        
        fetch('https://cherry-surprise-68554.herokuapp.com/api/page-forside?populate=*', { headers, method: 'GET' })
        .then(checkStatus)
        .then(parseJSON)
        .then(({ data }) => setCarouselforside(data))
        .catch((error) => setError(error))

    }, [])

    useEffect(() => {
        // Simulate loading progress
        let progress = 0;
        const interval = setInterval(() => {
          progress += 5;
          setPercentage(progress);
          if (progress >= 100) {
            clearInterval(interval);
            setTimeout(() => {
              setIsLoading(false); // Loading is complete, set isLoading to false after a delay
            }, 100); // Delay before setting isLoading to false (0.5 seconds)
          }
        }, 10); // Simulated loading interval in milliseconds
    
        // Fetch data or perform other loading tasks here
        // Example: fetchData();
    
        return () => {
          // Clear any cleanup tasks if needed
          clearInterval(interval);
        };
      }, []);
  



let data = [];

// This is the project
  const [prosjektet, setProsjektet] = useState();

  // fetch id from url
  const { id } = useParams();
  useEffect(() => {
    
   
    fetch("https://cherry-surprise-68554.herokuapp.com/api/prosjekter/"+id+"?populate=*", { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setProsjektet(data))
      .catch((error) => setError(error))


  }, [])

  
  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }
 



  const images = prosjektet?.attributes?.Galleri?.data?.map((item) => ({
    original: item.attributes?.url,
    thumbnail: item.attributes?.url,
  }));


  
  const videoUrl = "https://www.youtube-nocookie.com/embed/" + prosjektet?.attributes?.VideoLink;
  

  if (!prosjektet) {
    return (
       <>
           
        </>
    ) 
  }


  return (
    <>
    
        

        <div className="app">

        {isLoading ? (
            <Loading percentage={percentage} />
        ) : (

        <main className={`page landing-page sf-rel-top fade-in`}>
            {/* <section>
                    <div className="hero-prosjekter"></div>
                </section>
                */}
             
             { // section top text 
             
             }
                <section className="clean-info dark pt-4 bg-white">
                <div className="container mt-3"> <Link to="../Prosjekter"><i className="fa-solid fa-arrow-left" /> Tilbake til prosjekter</Link></div>
                <div className="container"><hr></hr></div>
                  <div className="container py-5">
                      <div className="row">
                        
                          <div className="col-md-12 col-xl-12 offset-xl-0 text-left mx-auto">
                              <h1 className="text-info">{ prosjektet.attributes.Tittel }</h1>
                              <div className="pt-4">
                                <ReactMarkdown className="line-break" children={prosjektet && prosjektet.attributes.Prosjekttekst_topp.replace(/\n/gi, "&nbsp; \n")} />
                                </div>
                          </div>
                      </div>
                  </div>
                </section>


                <section className="clean-info dark bg-white">
                    <div className="container">
                        <div>
                                <div>
                                    <section>
                                        <div className=""><img src={prosjektet && prosjektet.attributes.Hovedbilde.data.attributes.url} width="100%"></img>
                                        </div>
                                    </section>
                                </div>
                        </div>


        <div className=" mb-5 mt-5 text-left mx-auto">
            <div className="row">

                <div className="mb-3 text-left mx-auto text-left col-12">
                    <ReactMarkdown className="line-break" children={prosjektet && prosjektet.attributes.Tekstfelt_bred_Om_leilighetene.replace(/\n/gi, "&nbsp; \n")} />
                </div>

                <div className="col-md-6 text-left mx-auto">
             

                    <div key={id} className="">
                        <h3>{ prosjektet.attributes.Overskrift_info1 }</h3>
                        <ReactMarkdown className="line-break" children={prosjektet && prosjektet.attributes.Tekst_info1.replace(/\n/gi, "&nbsp; \n")} />
                    </div>

                   
                </div>
                    <div className="col-md-6 text-left mx-auto">
                        <h3>{ prosjektet.attributes.Overskrift_info2 }</h3>
                        <ReactMarkdown className="line-break" children={prosjektet && prosjektet.attributes.Tekst_info2.replace(/\n/gi, "&nbsp; \n")} />
                    </div>
                </div>
        </div>

        <div className=" mb-5 mt-5">
            <div className="row">
                <div className="container sf-img-maxwidth">
             
                <ReactMarkdown className="line-break" children={prosjektet && prosjektet.attributes.Tekstfelt_bred_Supplement.replace(/\n/gi, "&nbsp; \n")} />
                </div>
               
            </div>
        </div>


       
        
                {(() => {
                        if (prosjektet.attributes.VideoLink) {
                            return (

                                <div className="mb-5">
                                    <div className="">
                                        <h3>Videopresentasjon</h3>
                                    </div>
                                    <div className="videoWrapper">
                                        <iframe width="100%" height="auto" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </div>
                                </div>
                           
                            )
                        } else {
                            return (
                                <div></div>
                            )
                        }
                    })()}




                    {images && (
                        <div className="">
                            <div className="">
                            <h3>Bildegalleri</h3>
                            </div>
                            <ImageGallery items={images} />
                        </div>
                    )}


        




                 </div> 
                </section>
            </main>

           
            )}

            {isLoading && (
            <div className="loading-bar">
              <div className="loading-progress" style={{ width: `${percentage}%` }}></div>
            </div>
            )}


        </div>

        <div className="container-fluid">
        
            <div className="container mb-5 mt-5">
                {(() => {
                    if (prosjektet.attributes.Kartlink) {
                        return (
                            <iframe className="smash-map-offset" src={ prosjektet.attributes.Kartlink } width="100%" height="750" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        )
                    } else {
                        return (
                            <div></div>
                        )
                    }
                })()}
            </div>
        </div>
    </>
  );
}

export default Prosjektone;