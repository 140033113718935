import React from 'react'
import { Link } from "react-router-dom";

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Oval } from  'react-loader-spinner';
import ReactMarkdown from 'react-markdown';
import Loading from '../components/Loading';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };

const Footer = () => {
  const [showDiv, setShowDiv] = useState(false);
  const [error, setError] = useState(null);
  // Dette er footerdata
  const [footerdata, setFooterdata] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);


  useEffect(() => {
  
 
    fetch("https://cherry-surprise-68554.herokuapp.com/api/footer", { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setFooterdata(data))
      .catch((error) => setError(error))

    }, [])


    useEffect(() => {
      // Simulate loading progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 5;
        setPercentage(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setIsLoading(false); // Loading is complete, set isLoading to false after a delay
          }, 100); // Delay before setting isLoading to false (0.5 seconds)
        }
      }, 10); // Simulated loading interval in milliseconds
  
      // Fetch data or perform other loading tasks here
      // Example: fetchData();
  
      return () => {
        // Clear any cleanup tasks if needed
        clearInterval(interval);
      };
    }, []);



    useEffect(() => {
      // Delay the showing of the div by 2000 milliseconds (2 seconds)
      const delay = setTimeout(() => {
        setShowDiv(true);
      }, 2000);
  
      return () => clearTimeout(delay);
    }, []);






  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }



  return (
    <>
    {isLoading ? (
        <Loading percentage={percentage} />
      ) : (

        <div className={`delayed-div ${showDiv ? 'show' : ''}`}>
        {/* The content of the delayed div */}
        <footer className={`Footer sf-rel-top fade-in`}>
          <div className="container-fluid">
                  <div className="row sf-footer">
                      <div className="align-self-center sf-auto"> 
                          <br />
                            <div className="text-white mt-3 mb-3 p-1"><span className=""><ReactMarkdown className="line-break" children={footerdata && footerdata.attributes.Innhold.replace(/\n/gi, "&nbsp; \n")} /></span><br />
                            <p className="text-white-50 sf-bottom">Kopirett © 2023 Adriatic Invest<br/>Webløsning av Smash Fabric</p><div className="text-center">

                
                                  
                                </div>
                            </div>
                      </div>
                      <div></div>
                  </div>
              </div>





          
        </footer>
      </div>
        
        )}

            {isLoading && (
            <div className="loading-bar">
              <div className="loading-progress" style={{ width: `${percentage}%` }}></div>
            </div>
            )}
      
    </>
  );
}

export default Footer;
