import { useEffect, useState } from 'react';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);

// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };

const LinkerComp = () => {
  const [error, setError] = useState(null);
  const [linker, setLinker] = useState([]);

  useEffect(() => {
    fetch('https://cherry-surprise-68554.herokuapp.com/api/linkers', { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setLinker(data))
      .catch((error) => setError(error))
  }, [])

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }


  return (
    <div className="Linker">


       
    


            <div className="">
                {linker.map(({ id, attributes }) => 
                
                
                <div key={id}>
                  <div className="row pb-3">
                    <div className="col-sm-4">
                      <img className="rounded img-fluid w-100 fit-cover sf-prosjektbilde" src="assets/img/prosjekter/Render%20OKRUK%20antracit_6%20-%20Photo%20(1).jpg"/>
                    </div>
                    <div className="col-sm-8">
                      <div className="py-4">
                          <h4 className="subheader">{attributes.linkoverskrift}</h4>
                          <h6 className="subheader"><a className="" target="_blank" href={attributes.linkadresse}>{attributes.linkadresse}</a></h6>
                          <p>Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
                      </div>
                    </div>
                  </div>

                    
                </div> 
                
                )}




            
            </div>
                
       

    



    </div>
  );
};



export default LinkerComp;
  