import React from 'react'
import emailjs from 'emailjs-com';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_bc4lxdj";
const TEMPLATE_ID = "template_fps6g6a";
const USER_ID = "aBF5N3xx-CFT3oRuQ";

const ContactForm = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Meldingen ble sendt'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, det skjedde en feil',
          text: error.text,
        })
      });
    e.target.reset()
  };

return (




    <div className="AppForm mb-5">


      <form onSubmit={handleOnSubmit} className="p-3 p-xl-4" method="post">
          <div className="mb-3"><input className="form-control" label='Navn' type="text" id='form-input-control-last-name' name='user_name' placeholder='Navn...' /></div>
          <div className="mb-3"><input className="form-control" icon='mail' iconposition='left' label='Epost' id='form-input-control-email' type="email" id="email-1" name='user_email' placeholder='Epost...' /></div>
          <div className="mb-3"><textarea className="form-control" label='Melding' id='form-textarea-control-opinion' name="user_message" rows="6" placeholder="Melding..." required></textarea></div>
          <div><button className="btn btn-primary d-block" type="submit">Send</button></div>
      </form>


    </div>
  );
}

export default ContactForm