import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { Oval } from  'react-loader-spinner';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);

// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };

const Prosjekter = () => {
  const [error, setError] = useState(null);
  const [prosjekter, setProsjekter] = useState([]);

  useEffect(() => {
    fetch('https://cherry-surprise-68554.herokuapp.com/api/prosjekter?populate=*', { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setProsjekter(data))
      .catch((error) => setError(error))
  }, [])

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  if (!prosjekter) {
    return (
      <>
       <div className="align-middle">Loading</div>
 
            </>
    ) 
  }

  return (
    <div>

        <div className="container-fluid bg-light-gray">
          <div className="container-sm">
  
          <section className=" clean-info dark bg-light-gray">
          
                  
          <div className="container col-md-8 col-xl-10 offset-xl-0 text-center mx-auto">
                      <h1 className="text-info display-5 pt-5 pb-2 ">Våre prosjekter</h1>
                    
                  </div>

            <div className="py-4">
              <div className="row">
              
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 no-gutters">
              
                  { Object(prosjekter.map(({ id, attributes }) => 
                  
                  
                    <div className="p-2 col mb-5 mb-xl-0 pb-5 pb-xl-0 shadow-5" key={id}>
                      <div className="p-4 bg-white slide-in">
                        <div><Link to={'/prosjekt/' + id }><img className="img-fluid w-100 fit-cover sf-prosjektbilde" src={attributes?.Hovedbilde?.data?.attributes?.url} /></Link>
                          <div className="py-4">
                            <h4 className="subheader pb-4">{attributes.Tittel}</h4>
                            <div><ReactMarkdown className="line-break" children={attributes.Prosjekttekst_topp.replace(/\n/gi, "&nbsp; \n")} /></div>
                            <div className="p-3"></div><Link to={'/prosjekt/' + id }><span className="btn btn-primary btn-lg mr-2" role="button">Mer om prosjektet</span></Link>
                            <div class="pb-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  

                  ))}

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </div>
    
  );
};



export default Prosjekter;
  