import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Nav from './components/Nav';
import ProsjekterComp from './components/ProsjekterComp';
import Footer from './components/Footer';
import reportWebVitals from './reportWebVitals';
import Prosjekter from './pages/Prosjekter';
import ScrollToTop from "./helpers/ScrollToTop";
import TagManager from "react-gtm-module";


import { BrowserRouter } from "react-router-dom";

const tagManagerArgs = {
  gtmId: 'GTM-P6FXVMF'
}

TagManager.initialize(tagManagerArgs)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <App/>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
