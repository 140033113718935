import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);

// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };




const BoxComponent = () => {
  const [error, setError] = useState(null);
  const [flottekroatia, setFlottekroatia] = useState(null);
  

  useEffect(() => {
    fetch('https://cherry-surprise-68554.herokuapp.com/api/forsideflottekroatiaboks?populate=*', { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setFlottekroatia(data))
      .catch((error) => setError(error))
  }, [])

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <div className="sf-bg-opacity">
    { flottekroatia != null &&
       
          <div key={ flottekroatia.id }>
           
       
                  
                    <div className="p-5">
                    <h2 className="font-weight-bold text-white mb-3">{ flottekroatia.attributes.ForsideboksComp.Overskrift } 
                              
                              </h2>
                              
                              
                              <p className="mb-4 text-white">{ flottekroatia.attributes.ForsideboksComp.Tekstinnhold }</p>
    
                              <p class="mt-5"><Link to="/om-oss"><span className="btn btn-light btn-lg" role="button">{  flottekroatia.attributes.ForsideboksComp.ButtonTekst  }</span></Link>
                              </p>
                    </div>
                    
           
          
              
           
          </div>
        }  
    </div>
  );

};

export default BoxComponent;
  