import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Divide as Hamburger } from 'hamburger-react';
import DialogBox from './TranslateModal';


// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);
// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };
function BootstrapNavbar() {
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState(null);
  const [toppmenies, setToppmenies] = useState([]);
  const closeMenu = () => setExpanded(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    fetch('https://cherry-surprise-68554.herokuapp.com/api/toppmenies', { headers, method: 'GET' })
    .then(checkStatus)
    .then(parseJSON)
    .then(({ data }) => setToppmenies(data))
    .catch((error) => setError(error))
  }, []);
  

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  


  function CloseMenu_fn(){
    
    closeMenu();
  }
  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }
    
  return (
    <main className="page landing-page sf-rel-top">
    
        <div className="container">
        
          <Navbar bg="light" className="sf-navbar navbar navbar-light navbar-expand-lg fixed-top bg-white clean-navbar sf-navmargin" expand="lg" expanded={expanded}>
          
            <Navbar.Brand href="/">
              <img src="assets/img/Logo%20standard%20horisontal.jpg" width="212" height="65" />
            </Navbar.Brand>
            
            <Navbar.Toggle>
              <Hamburger color="#333" size="24" hideOutline={false} toggled={expanded} toggle={setExpanded} aria-controls="basic-navbar-nav" onToggle={toggled => {
                if (toggled) {
                  setExpanded(!expanded);
                  // open a menu
                } else {
                  // close a menu
                  closeMenu();
                }
              }} 
              />
            </Navbar.Toggle>
            
            
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="ml-auto mr-3">
              
                <Nav className="mr-auto text-center justify-content-end">
                  {toppmenies.map(({ id, attributes }) => <li key={id}>
                  <Nav.Link as={Link} className="nav-link custom-nav-link sf-menufont" to={attributes.slug} onClick={CloseMenu_fn}>{attributes.menytekst}</Nav.Link></li>)}
                    
                  </Nav>
              </div>
              <div className="image-container mt-4 mt-md-4 centered-div">
                <a href="https://www.adriaticinvest.no"><img src="../assets/img/flags/norway.png" className="sf-flags" alt="Norwegian"></img></a>
                <a href="https://translate.google.com/translate?sl=no&tl=en&u=www.adriaticinvest.no"><img src="../assets/img/flags/england.png" className="sf-flags" alt="English (Google Translate)"></img></a>
                <br/><div class="smaller-text">Translation powered<br/>by Google Translate</div>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        
    </main>
  );
}

export default BootstrapNavbar;