import React from 'react';
import '../assets/css/Loading.css';

const Loading = ({ percentage }) => {
  return (
    <div className={`loading-container ${percentage === 100 ? 'fade-out' : ''}`}>
      <div className={`loading-spinner ${percentage === 100 ? 'spin-complete' : ''}`}></div>
      <div className={`loading-text ${percentage === 100 ? 'fade-in' : ''}`}>
        Laster {percentage}%
      </div>
    </div>
  );
};

export default Loading;