import React from 'react'
import ProsjekterCompFront from '../components/ProsjekterCompFront';
import { Link } from "react-router-dom";
import FlotteKroatia from '../components/FlotteKroatia';
import CarouselForside from '../components/CarouselForside';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Oval } from  'react-loader-spinner';
import ReactMarkdown from 'react-markdown';
import ReactGA from 'react-ga';
import Loading from '../components/Loading';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };





const Hjem = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);

  const [error, setError] = useState(null);
  // Dette er hjemdata
  const [hjemdata, setHjemdata] = useState();

  useEffect(() => {
  
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    fetch("https://cherry-surprise-68554.herokuapp.com/api/page-forside?populate=*", { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setHjemdata(data))
      .catch((error) => setError(error))

    }, [])


    useEffect(() => {
      // Simulate loading progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 5;
        setPercentage(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setIsLoading(false); // Loading is complete, set isLoading to false after a delay
          }, 100); // Delay before setting isLoading to false (0.5 seconds)
        }
      }, 10); // Simulated loading interval in milliseconds
  
      // Fetch data or perform other loading tasks here
      // Example: fetchData();
  
      return () => {
        // Clear any cleanup tasks if needed
        clearInterval(interval);
      };
    }, []);


  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }




  return (
    <div className="">
      {isLoading ? (
        <Loading percentage={percentage} />
        ) : (
            <main className={`page landing-page sf-rel-top bg-white fade-in`}>
            <div id="fade-in-div"><CarouselForside /></div>
                
                <section className="container clean-info dark pt-4 mt-5 bg-white">
                    <div className="container py-xl-5">
                        <div className="row">
                            <div className="col-xl-6 offset-xl-0 my-5 mt-xl-0 text-left pr-md-5">
                                <h1 className="display-4">{hjemdata && hjemdata.attributes.Overskrift}</h1>
                                <div className="mt-4 text-left"><span><ReactMarkdown className="line-break" children={hjemdata && hjemdata.attributes.Tekstinnhold.replace(/\n/gi, "&nbsp; \n")} /></span></div><br/><Link to="/kontakt"><span className="btn btn-primary btn-lg mr-2" role="button" href="#">Kontakt oss</span></Link>
                            </div>
                            <div className="col-xl-6 offset-xl-0 text-left mx-left my-5 mt-xl-0 sf-mid-box">
                            <FlotteKroatia />
                            </div>

                        </div>
                    </div>
                    
                      
                  

                    
                  </section>
                  
                  <ProsjekterCompFront />
              </main>
        
              
      )}

      {isLoading && (
      <div className="loading-bar">
        <div className="loading-progress" style={{ width: `${percentage}%` }}></div>
      </div>
    )}

    </div>
  )
}

export default Hjem