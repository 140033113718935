import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);

// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };




const CarouselForside = () => {
  const [error, setError] = useState(null);
  
  const [carouselforside, setCarouselforside] = useState(null);

  useEffect(() => {
    fetch('https://cherry-surprise-68554.herokuapp.com/api/page-forside?populate=*', { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setCarouselforside(data))
      .catch((error) => setError(error))
  }, [])

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <div className="">










    { carouselforside != null &&
       
          <div key={ carouselforside.id }>
          <section>



            <div className="carousel slide sf-hero-height" data-ride="carousel" data-interval="false" id="carousel-2">
                <div className="carousel-inner h-100">

                    

                        <div>
                            <div className="sf-hero-height bg-black"><img src={ carouselforside.attributes.Forsidehero[0].BildeUrl } className="w-100 d-block position-absolute h-100 fit-cover sf-hero-overlay sf-hero-height black-overlay" alt="Slide Image"></img>
                                <div className="container d-flex flex-column justify-content-center h-100">
                                    <div className="row">
                                    
                                        <div className="col-md-8 col-xl-10 offset-md-2">
                                            <div>
                                                <h1 className="text-uppercase pb-4 font-weight-bold text-white"><span className="sf-hero-bgtext slide-in-text"> { carouselforside.attributes.Forsidehero[0].Overskrift }</span></h1>
                                                <Link to="/prosjekter"><span className="mt-3 ml-xl-5 btn btn-light btn-lg mr-2 hero-btn" role="button" href="#">{ carouselforside.attributes.Forsidehero[0].Undertekst }</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                
            </div>
          </section>
          
        </div>
        }  
    </div>
  );

};

export default CarouselForside;
  