import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Nav from './components/Nav';

import Footer from './components/Footer';
import reportWebVitals from './reportWebVitals';
import Prosjekter from './pages/Prosjekter';
import Linker from './pages/Linker';
import Omoss from './pages/Omoss';
import Omkroatia from './pages/Omkroatia';
import Kontakt from './pages/Kontakt';
import Hjem from './pages/Hjem';
import Prosjekt from './pages/Prosjekt';

import { Routes, Route } from "react-router-dom";
  
import ReactGA from 'react-ga';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

ReactGA.initialize('G-6VGVSRKJZ5');



function App() {
  return (
    <>
       <Nav />
        <div className="app">
            <Routes>
                <Route path="/" element={<Hjem />} />
                <Route path="/prosjekter/" element={<Prosjekter />} />
                <Route path="/linker/" element={<Linker />} />
                <Route path="/om-oss/" element={<Omoss />} />
                <Route path="/om-kroatia/" element={<Omkroatia />} />
                <Route path="/kontakt/" element={<Kontakt />} />
                <Route path="/prosjekt/" element={<Prosjekt />} />
                <Route path="/prosjekt/:id/" element = { <Prosjekt /> } />
              </Routes>

        
        </div>
        <Footer/>
        <CookieBanner
          message="Vi bruker informasjonskapsler for å gjøre din opplevelse på nettsiden bedre. Du kan velge hvilke du ønsker at vi benytter."
          privacyPolicyLinkText = "Personvernerklæring"
          necessaryOptionText = "Nødvendige"
          preferencesOptionText = "Sidetilpasninger"
          statisticsOptionText = "Statistikk"
          marketingOptionText = "Markedsføring"
          acceptSelectionButtonText = "Aksepter valgte"
          acceptAllButtonText ="Aksepter alle"
          showAcceptSelectionButton = "true"
          
          
          onAcceptPreferences = {() => { 
            // load your preferences trackers here
          }}
          onAcceptStatistics = {() => {
            // load your statistics trackers here
          }}
          onAcceptMarketing = {() => {
            // load your marketing trackers here
          }}
        />
    </>
  );
}

export default App;
