import React from 'react'
import LinkerComp from '../components/LinkerComp';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { Oval } from  'react-loader-spinner';
import ReactGA from 'react-ga';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };

const Linker = () => {

  const [error, setError] = useState(null);
  // Dette er hjemdata
  const [linker, setLinker] = useState();

  useEffect(() => {
  
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetch("https://cherry-surprise-68554.herokuapp.com/api/pagelinker?populate=*", { headers, method: 'GET' })
      .then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setLinker(data))
      .catch((error) => setError(error))

    }, [])


  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  if (!linker) {
    return (
       <>
            <div className="container sf-loader">
            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{position: "absolute", left: "50%", top: "200px", transform: "translate(-50%, -50%)"}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
              
        
            </div>
        </>
    ) 
  }

  return (
    <div>
           

            <main className="page landing-page sf-rel-top">
                <section className="clean-block clean-info dark pt-4 bg-white">
                    <div className="container py-5">
                        <div className="row">
                  
                            <div className="col-md-12 col-xl-12 offset-xl-0 text-left mx-auto">
                                <h1 className="text-info font-weight-bold">{linker && linker.attributes.Overskrift}</h1>
                                <div className="mt-4 mb-4"><span><ReactMarkdown className="line-break" children={linker && linker.attributes.Innhold.replace(/\n/gi, "&nbsp; \n")} /></span></div>
                                <LinkerComp />
                            </div>
                            
                        </div>

                    </div>
                    
                  </section>
              </main>
            

        
          </div>
  )
}

export default Linker