import React from 'react'
import '../../node_modules/flag-icons/css/flag-icons.min.css';
import ContactForm from '../components/Contactform';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Oval } from  'react-loader-spinner';
import ReactGA from 'react-ga';
import Loading from '../components/Loading';

// Parses the JSON returned by a network request
const parseJSON = (resp) => (resp.json ? resp.json() : resp);


// Checks if a network request came back fine, and throws an error if not
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

const headers = { 'Content-Type': 'application/json' };


const Omkroatia = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);

    const [error, setError] = useState(null);
    // Dette er kontaktdata
    const [kontakt, setKontakt] = useState();
  
    useEffect(() => {
    
      ReactGA.pageview(window.location.pathname + window.location.search);
      fetch("https://cherry-surprise-68554.herokuapp.com/api/page-kontakts?populate=*", { headers, method: 'GET' })
        .then(checkStatus)
        .then(parseJSON)
        .then(({ data }) => setKontakt(data))
        .catch((error) => setError(error))
  
      }, [])
  
  

      useEffect(() => {
        // Simulate loading progress
        let progress = 0;
        const interval = setInterval(() => {
          progress += 5;
          setPercentage(progress);
          if (progress >= 100) {
            clearInterval(interval);
            setTimeout(() => {
              setIsLoading(false); // Loading is complete, set isLoading to false after a delay
            }, 100); // Delay before setting isLoading to false (0.5 seconds)
          }
        }, 10); // Simulated loading interval in milliseconds
    
        // Fetch data or perform other loading tasks here
        // Example: fetchData();
    
        return () => {
          // Clear any cleanup tasks if needed
          clearInterval(interval);
        };
      }, []);
  

    if (error) {
      // Print errors if any
      return <div>An error occured: {error.message}</div>;
    }


  return (
    <div>
      {isLoading ? (
        <Loading percentage={percentage} />
      ) : (
          <main className={`page landing-page sf-rel-top fade-in`}>
            {/* <section>
                    <div className="hero-prosjekter"></div>
                </section>
                */}
             
               
              <section className="clean-info dark pt-4 bg-white">
                  <div className="py-5 container">
                      <div className="row">
                
                          <div className="col-xl-12 offset-xl-0 text-left mx-auto">
                              <h1 className="text-info font-weight-bold">{kontakt && kontakt[0].attributes.Tittel}</h1>
                              
                          </div>
                        
                      </div>
                  </div>

                </section>


                <div className="container">
                    <div className="row">

                      <div className="container">
                          <div className="row mt-5 mt-xl-0">
                            
                              {kontakt && kontakt[0].attributes.Kontaktkort.map((kontaktkort) => (
                                <div className="col-md-4">
                                
                                    <div className="d-flex align-items-top p-3 shadow rounded-lg sf-contact-card">
                                            
                                            <div className="sf-flag">
                                                <div className={kontaktkort.Flaggkode}>
                                                </div>
                                            </div>
                                            <div className="px-2 sf-contact">
                                                <h6 className="font-weight-bold mb-0">{kontaktkort.Land}</h6><br/>
                                                <p className="mb-0">
                                                  {kontaktkort.Navn && <span className="card-text">{kontaktkort.Navn}<br/></span>}
                                                  {kontaktkort.Telefonnr && <span className="card-text">{kontaktkort.Telefonnr}</span>}
                                                  {kontaktkort.Epost && <p className="card-text"><a href={`mailto:${kontaktkort.Epost}`}>{kontaktkort.Epost}</a></p>}
                                                  {kontaktkort.Nettside && <p className="card-text"><a href={`http://${kontaktkort.Nettside}`} target="_blank">{kontaktkort.Nettside}</a></p>} 
                                                </p>
                                            </div>
                                      
                                    </div>
                                  </div>
                              ))}
                              </div>


                              <div className="row">
                                <div className="col-md-6">
                                  <div className="">
                                    <ContactForm />
                                  </div>
                                </div>
                                
                                <div className="col pb-5">
                                  <img src="https://www.adriaticinvest.no/assets/img/general/AdobeStock_291194818_Preview.jpg" width="100%" />
                                </div>
                              </div>


                          
                      </div>


                    </div>
                  </div>

                  


              
              
            </main>

            )}

          {isLoading && (
          <div className="loading-bar">
            <div className="loading-progress" style={{ width: `${percentage}%` }}></div>
          </div>
          )}


      </div>
  )
}

export default Omkroatia